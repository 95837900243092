import { createSelector } from "reselect";
import _ from "lodash";

export const getStats = state => state.stats; //_.get(state, 'stats')
export const isValidating = state => _.get(state, "stats.validating", true);
export const isInitializing = state => _.get(getStats(state), "initializing");

export const getEssaysAwaitingPeerReviewsGiven = createSelector(
  getStats,
  stats => _.get(stats, "essaysAwaitingPeerReviewsGiven", [])
);

export const getEssaysAwaitingConfirmation = createSelector(getStats, stats =>
  _.get(stats, "essaysAwaitingConfirmation", [])
);

export const getRejected = createSelector(getStats, stats =>
  _.get(stats, "rejected", {})
);

export const getRejectedIds = createSelector(getRejected, rejected =>
  Object.keys(rejected)
);

export const getRejectedAnswer = createSelector(
  (state, quizId) => _.get(getRejected(state), quizId),
  rejected => _.get(rejected, "answer")
);
