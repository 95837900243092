import Text from "./Text";

const TextF4 = Text.withComponent("h3");

TextF4.defaultProps = {
  fontSize: ["1.25rem", "1.75rem"],
  fontFamily: "TTNormsProBold",
  lineHeight: "1.48",
  color: "#29264C"
};

export default TextF4;
