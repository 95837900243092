import React from "react";
import PropTypes from "prop-types";

function romanize(num) {
  var lookup = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1
    },
    roman = "",
    i;
  for (i in lookup) {
    while (num >= lookup[i]) {
      roman += i;
      num -= lookup[i];
    }
  }
  return roman;
}

const Romanize = props => (
  // Correct! This use of <div> is legitimate because div is a valid HTML tag:
  <span>{romanize(props.num)}.</span>
);

Romanize.propTypes = {
  num: PropTypes.number.isRequired
};

export default Romanize;
